@use '@/core/tamagoshiv2/tokens/colors';
@use '@/core/tamagoshiv2/tokens/spacings';

@import '@/productDiscovery/HeaderLeonidas/styles/commons';

.root {
  @include buttonDefault;

  &:hover {
    @include buttonHover;
  }

  &:focus {
    @include buttonFocus;
  }

  &:active {
    @include buttonPressed;
  }

  &:disabled {
    @include buttonDisabled;
  }
}
